var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-title',{attrs:{"text":_vm.$t('title.personal_information')}}),_c('form-content',[_c('validation-observer',{attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"mx-auto max-w-md"},[_c('div',{staticClass:"flex flex-col sm:flex-row gap-2"},[_c('validation-provider',{staticClass:"flex-grow",attrs:{"name":_vm.$t('label.city_of_birth'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block mb-2 font-bold",attrs:{"for":"city"},domProps:{"textContent":_vm._s(_vm.i18nLabel(_vm.$t('label.city_of_birth')))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cityOfBirth),expression:"cityOfBirth"}],staticClass:"form-control",class:{ 'is-invalid': errors[0] },attrs:{"type":"text","autocomplete":"address-level2","id":"city"},domProps:{"value":(_vm.cityOfBirth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cityOfBirth=$event.target.value}}}),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)}),_c('validation-provider',{staticClass:"w-min",attrs:{"name":_vm.$t('label.postal_code'),"rules":"cap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-4",staticStyle:{"min-width":"10rem"}},[_c('label',{staticClass:"block mb-2 font-bold whitespace-nowrap",attrs:{"for":"postal_code"},domProps:{"textContent":_vm._s(_vm.i18nLabel(_vm.$t('label.postal_code')))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postalCode),expression:"postalCode"}],staticClass:"form-control w-auto",class:{ 'is-invalid': errors[0] },attrs:{"type":"text","inputmode":"decimal","autocomplete":"postal-code","maxlength":"5","id":"postal_code"},domProps:{"value":(_vm.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postalCode=$event.target.value}}}),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":_vm.$t('label.gender'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"flex flex-col sm:flex-row sm:items-center -mx-4"},[_c('div',{staticClass:"px-4"},[_c('label',{staticClass:"block mb-2 font-bold",attrs:{"for":"gender_mail"},domProps:{"textContent":_vm._s(_vm.i18nLabel(_vm.$t('label.gender')))}})]),_c('div',{staticClass:"flex items-center -mx-2 px-4"},[_c('div',{staticClass:"mb-2 flex items-center px-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],ref:"genderFemale",staticClass:"text-red-500 focus:ring-red-500 mr-2",class:{ 'is-invalid': errors[0] },attrs:{"type":"radio","id":"gender_female","name":"gender"},domProps:{"value":_vm.$t('label.female'),"checked":_vm._q(_vm.gender,_vm.$t('label.female'))},on:{"change":function($event){_vm.gender=_vm.$t('label.female')}}}),_c('label',{attrs:{"for":"gender_female"},domProps:{"textContent":_vm._s(_vm.$t('label.female'))}})]),_c('div',{staticClass:"mb-2 flex items-center px-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],ref:"genderMale",staticClass:"text-red-500 focus:ring-red-500 mr-2",class:{ 'is-invalid': errors[0] },attrs:{"type":"radio","id":"gender_male","name":"gender"},domProps:{"value":_vm.$t('label.male'),"checked":_vm._q(_vm.gender,_vm.$t('label.male'))},on:{"change":function($event){_vm.gender=_vm.$t('label.male')}}}),_c('label',{attrs:{"for":"gender_male"},domProps:{"textContent":_vm._s(_vm.$t('label.male'))}})])])]),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('label.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block mb-2 font-bold",attrs:{"for":"name"},domProps:{"textContent":_vm._s(_vm.i18nLabel(_vm.$t('label.name')))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",class:{ 'is-invalid': errors[0] },attrs:{"type":"text","autocomplete":"given-name","id":"name"},domProps:{"value":(_vm.name)},on:{"blur":function($event){return _vm.suggestGender(_vm.name)},"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('label.email_short'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"block mb-2 font-bold",attrs:{"for":"email"},domProps:{"textContent":_vm._s(_vm.i18nLabel(_vm.$t('label.email')))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"email",staticClass:"form-control",class:{ 'is-invalid': errors[0] },attrs:{"type":"email","id":"email","autocomplete":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('label.accept'),"rules":{ accept: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.accept),expression:"accept"}],staticClass:"rounded text-red-500 focus:ring-red-500 mr-2 mt-px",class:{ 'is-invalid': errors[0] },attrs:{"type":"checkbox","id":"accept"},domProps:{"checked":Array.isArray(_vm.accept)?_vm._i(_vm.accept,null)>-1:(_vm.accept)},on:{"change":function($event){var $$a=_vm.accept,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.accept=$$a.concat([$$v]))}else{$$i>-1&&(_vm.accept=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.accept=$$c}}}}),_c('label',{staticClass:"text-sm",attrs:{"for":"accept"},domProps:{"innerHTML":_vm._s(
                    _vm.$t('label.accept', {
                      link: '/img/CGU.png',
                    })
                  )}})]),_c('span',{staticClass:"form-error",domProps:{"textContent":_vm._s(errors[0])}})])]}}],null,true)}),_c('div',{staticClass:"mb-12"},[_c('button',{staticClass:"btn bg-red-500 hover:bg-red-600 focus:bg-red-700 text-white w-full ",domProps:{"textContent":_vm._s(_vm.$t('button.view_reading'))}}),_c('p',{staticClass:"text-xs opacity-50 mt-4 text-center",domProps:{"textContent":_vm._s(_vm.$t('misc.private_information'))}})])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }