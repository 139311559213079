<template>
  <div>
    <form-title :text="$t('title.personal_information')" />
    <form-content>
      <validation-observer
        tag="form"
        v-slot="{ handleSubmit }"
        @submit.prevent="onSubmit"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="mx-auto max-w-md">
            <div class="flex flex-col sm:flex-row gap-2">
              <!-- TODO: //GoogleMapsAutocomplete -->
              <validation-provider
                :name="$t('label.city_of_birth')"
                rules="required"
                v-slot="{ errors }"
                class="flex-grow"
              >
                <div class="mb-4">
                  <label
                    for="city"
                    class="block mb-2 font-bold"
                    v-text="i18nLabel($t('label.city_of_birth'))"
                  />

                  <input
                    type="text"
                    v-model="cityOfBirth"
                    autocomplete="address-level2"
                    id="city"
                    class="form-control"
                    :class="{ 'is-invalid': errors[0] }"
                  />
                  <span class="form-error" v-text="errors[0]" />
                </div>
              </validation-provider>
              <validation-provider
                :name="$t('label.postal_code')"
                rules="cap"
                v-slot="{ errors }"
                class="w-min"
              >
                <div class="mb-4" style="min-width: 10rem;">
                  <label
                    for="postal_code"
                    class="block mb-2 font-bold whitespace-nowrap"
                    v-text="i18nLabel($t('label.postal_code'))"
                  />

                  <input
                    type="text"
                    inputmode="decimal"
                    v-model="postalCode"
                    autocomplete="postal-code"
                    maxlength="5"
                    id="postal_code"
                    class="form-control w-auto"
                    :class="{ 'is-invalid': errors[0] }"
                  />
                  <span class="form-error" v-text="errors[0]" />
                </div>
              </validation-provider>
            </div>
            <validation-provider
              :name="$t('label.gender')"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-3">
                <div class="flex flex-col sm:flex-row sm:items-center -mx-4">
                  <div class="px-4">
                    <label
                      for="gender_mail"
                      class="block mb-2 font-bold"
                      v-text="i18nLabel($t('label.gender'))"
                    />
                  </div>
                  <div class="flex items-center -mx-2 px-4">
                    <div class="mb-2 flex items-center px-2">
                      <input
                        type="radio"
                        v-model="gender"
                        ref="genderFemale"
                        id="gender_female"
                        name="gender"
                        :value="$t('label.female')"
                        class="text-red-500 focus:ring-red-500 mr-2"
                        :class="{ 'is-invalid': errors[0] }"
                      />
                      <label for="gender_female" v-text="$t('label.female')" />
                    </div>
                    <div class="mb-2 flex items-center px-2">
                      <input
                        type="radio"
                        v-model="gender"
                        ref="genderMale"
                        id="gender_male"
                        name="gender"
                        :value="$t('label.male')"
                        class="text-red-500 focus:ring-red-500 mr-2"
                        :class="{ 'is-invalid': errors[0] }"
                      />
                      <label for="gender_male" v-text="$t('label.male')" />
                    </div>
                  </div>
                </div>
                <span class="form-error" v-text="errors[0]" />
              </div>
            </validation-provider>
            <validation-provider
              :name="$t('label.name')"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-4">
                <label
                  for="name"
                  class="block mb-2 font-bold"
                  v-text="i18nLabel($t('label.name'))"
                />
                <input
                  type="text"
                  v-model="name"
                  @blur="suggestGender(name)"
                  autocomplete="given-name"
                  id="name"
                  class="form-control"
                  :class="{ 'is-invalid': errors[0] }"
                />
                <span class="form-error" v-text="errors[0]" />
              </div>
            </validation-provider>
            <validation-provider
              :name="$t('label.email_short')"
              rules="required|email"
              v-slot="{ errors }"
            >
              <div class="mb-6">
                <label
                  for="email"
                  class="block mb-2 font-bold"
                  v-text="i18nLabel($t('label.email'))"
                />
                <input
                  type="email"
                  id="email"
                  autocomplete="email"
                  v-model="email"
                  ref="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors[0] }"
                />
                <span class="form-error" v-text="errors[0]" />
              </div>
            </validation-provider>

            <validation-provider
              :name="$t('label.accept')"
              :rules="{ accept: { allowFalse: false } }"
              v-slot="{ errors }"
            >
              <div class="mb-4">
                <div class="flex">
                  <input
                    type="checkbox"
                    v-model="accept"
                    id="accept"
                    class="rounded text-red-500 focus:ring-red-500 mr-2 mt-px"
                    :class="{ 'is-invalid': errors[0] }"
                  />
                  <label
                    for="accept"
                    class="text-sm"
                    v-html="
                      $t('label.accept', {
                        link: '/img/CGU.png',
                      })
                    "
                  />
                </div>

                <span class="form-error" v-text="errors[0]" />
              </div>
            </validation-provider>
            <div class="mb-12">
              <button
                class="btn bg-red-500 hover:bg-red-600 focus:bg-red-700 text-white w-full "
                v-text="$t('button.view_reading')"
              />
              <p
                class="text-xs opacity-50 mt-4 text-center"
                v-text="$t('misc.private_information')"
              />
            </div>
          </div>
        </form>
      </validation-observer>
    </form-content>
  </div>
</template>

<script>
import i18n from "@/i18n.js";
import axios from "axios";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";

import { required, email } from "vee-validate/dist/rules";
import fr from "vee-validate/dist/locale/fr.json";
import it from "vee-validate/dist/locale/it.json";
import FormTitle from "./FormTitle.vue";
import FormContent from "./FormContent.vue";

extend("required", {
  ...required,
});
extend("email", {
  ...email,
});

extend("accept", {
  ...required,
  message: i18n.t("messages.accept_terms"),
});

extend("cap", {
  ...required,
  message: i18n.t("messages.cap"),
});

export default {
  name: "LastStep",
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTitle,
    FormContent,
  },
  data() {
    return {
      email: "",
      name: "",
      gender: "",
      cityOfBirth: "",
      postalCode: "",
      accept: false,
    };
  },
  created() {
    this.$store.state.locale === "fr" && localize("fr", fr);
    this.$store.state.locale === "it" && localize("it", it);

    this.email = this.savedUserData?.email ? this.savedUserData?.email : "";
    this.name = this.savedUserData?.name ? this.savedUserData?.name : "";
    this.gender = this.savedUserData?.gender ? this.savedUserData?.gender : "";
    this.cityOfBirth = this.savedUserData?.cityOfBirth
      ? this.savedUserData?.cityOfBirth
      : "";
    this.postalCode = this.savedUserData?.postalCode
      ? this.savedUserData?.postalCode
      : "";
  },
  computed: {
    savedUserData() {
      return this.$store.state.userData;
    },
  },

  methods: {
    onSubmit() {
      // in real world - should register a new guest user with this email...
      this.$emit("update-user-data", {
        email: this.email,
        name: this.name,
        gender: this.gender,
        cityOfBirth: this.cityOfBirth,
        postalCode: this.postalCode,
      });
    },
    async suggestGender(name) {
      if (!name) return;
      const firstName = name.split(" ")[0];
      const url = `https://api.genderize.io/?name=${firstName}`;

      try {
        const response = await axios.get(url);

        if (response?.data?.probability > "0.5") {
          if (response.data.gender === "male") {
            this.gender = this.$refs.genderMale.value;
          }

          if (response.data.gender === "female") {
            this.gender = this.$refs.genderFemale.value;
          }

          this.$refs.email.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
